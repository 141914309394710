var registration = {
	el: {
		doc: $(document),
		form: $('.js-form'),
		submitForm: $('.form__submit'),
		select: $(".js-registration-select"),
		registrationSubmitButton: $(".js-registration-submit"),
		searchContainer: $('.search-block'),
		registrationContainer: $('.registration'),
		resultsContainer: $('.results'),
		userData: $('.table-user-data'),
	},

	isEmail: function (value) {
		var regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/i;
		if (this.el.userData.find('#email').val() !== '')
			return regex.test(value);
	},

	isMobile: function (value) {
		var regex = /^(9)[\d]{8}$/;
		return regex.test(value);
	},

	formToJson: function (elem) {
		var unindexed_array = $(elem).serializeArray(),
			indexed_array = {};

		$.map(unindexed_array, function (n) {
			$(n).each(function () {
				n.value === 'true' || n.value === 'false' ? n.value = JSON.parse(n.value) : ''
			})
			indexed_array[n['name']] = n['value'];
		});

		return indexed_array;
	},

	signupEvents: function () {
		var self = this;

		this.el.submitForm.closest('.registration').hasClass('registration--form-visible') ? this.el.submitForm.show() : this.el.submitForm.hide()

		this.el.doc.on('click', '.js-registration-signup', function (e) {
			e.preventDefault();
			self.el.searchContainer.add(self.el.resultsContainer).hide();
			self.el.submitForm.show()

		}) 
	},

	postEvents: function () {

		var self = this;

		this.el.form.each(function () {
			var selfObj = $(this);

			selfObj.on('submit', function (e) {
				e.preventDefault();
				if (selfObj.hasClass('form__submit')) {

					if (self.el.userData.find('#mobile').val() === '' || self.el.userData.find('#contactname').val() === '') {
						self.el.userData.find('.validation-message').remove();
						self.el.userData.append('<p class="validation-message">Campos com * obrigatórios</p>')

					}
					else if (self.isMobile(self.el.userData.find('#mobile').val()) === false && self.isEmail(self.el.userData.find('#email').val()) === false) {
						self.el.userData.find('.validation-message').remove();
						self.el.userData.append('<p class="validation-message">Telemóvel Inválido</p>')
						self.el.userData.append('<p class="validation-message">Email Inválido</p>')

					}
					else if (self.isMobile(self.el.userData.find('#mobile').val()) === false && ((self.isEmail(self.el.userData.find('#email').val()) !== false) || (self.isEmail(self.el.userData.find('#email').val() === '' ))) ) {
						self.el.userData.find('.validation-message').remove();
						self.el.userData.append('<p class="validation-message">Telemóvel Inválido</p>')

					} else if (self.isEmail(self.el.userData.find('#email').val()) === false && ((self.isMobile(self.el.userData.find('#mobile').val()) !== false) || (self.isMobile(self.el.userData.find('#mobile').val() === '' )))) {
						self.el.userData.find('.validation-message').remove();
						self.el.userData.append('<p class="validation-message">Email Inválido</p>')

					} else {
						self.el.userData.find('.validation-message').remove();
						$.ajax({
							url: selfObj.attr('action'),
							type: selfObj.attr('method').toUpperCase(),
							data: JSON.stringify(self.formToJson(this)),
							contentType: 'application/json',
							dataType: 'json',
							beforeSend: function () {
								self.el.registrationSubmitButton.text(selfObj.data('msg-loading'))
							}
						}).done(function (data, textstatus, jqXHR) {
							if (jqXHR.status === 200) {
								self.el.userData.hide();
								self.el.registrationSubmitButton.hide();
								self.el.registrationContainer.find('>p').html(selfObj.data('msg-success')).show()

							}

						}).fail(function (jqXHR, textStatus) {

							if (jqXHR.status === 409) {
								self.el.registrationSubmitButton.append('<li><p class="full-width">A delegação associada a este cliente já atingiu o limite de inscrições</p></li>');
							}
						})
					}
				} else {

					if (selfObj.find('input').val().length < 4) {
						self.el.resultsContainer.find('ul').append('<li><p class="full-width"> Tem de escrever pelo menos 4 caracteres</p></li>').closest('.results').show();
					} else {
						$.ajax({
							url: selfObj.attr('action'),
							type: selfObj.attr('method').toUpperCase(),
							data: JSON.stringify(self.formToJson(this)),
							contentType: 'application/json',
							dataType: 'json',
							beforeSend: function () {
								self.el.resultsContainer.find('li').remove();
								self.el.resultsContainer.find('ul').append('<li><p class="full-width">' + selfObj.data('msg-loading')).closest('.results').show();
							}
						}).done(function (data, textstatus, jqXHR) {
							if (jqXHR.status === 200) {
								self.el.resultsContainer.find('li').remove();
								$.each(data, function (i) {
									var item = $('<li><p><strong>Código Cliente: </strong>' + data[i].customerCode + ' - ' + data[i].name + ' - ' + data[i].city + ' | <strong>NIF</strong>: ' + data[i].nif + ' | <strong>Delegação</strong>: ' + data[i].delegationName + '</p><a id=' + data[i].customerCode + ' data-index=' + (i + 1) + ' class="js-registration-signup" href="javascript:;">' + selfObj.data('msg-signup') + '</a></li>');


									item.find('.js-registration-signup').on('click', function () {
										self.el.registrationContainer.find('>p').html(selfObj.data('msg-info')).show()
										var userDetails = data[$(this).data('index') - 1];

										self.el.userData.find('#name').val(userDetails.name)
										self.el.userData.find('#contactname').val(userDetails.contactName)
										self.el.userData.find('#customercode').val(userDetails.customerCode)
										self.el.userData.find('#nif').val(userDetails.nif)
										self.el.userData.find('#streetname').val(userDetails.streetName)
										self.el.userData.find('#nousenumber').val(userDetails.streetName)
										self.el.userData.find('#place').val(userDetails.city)
										self.el.userData.find('#postalcode').val(userDetails.postalCode)
										self.el.userData.find('#mobile').val(userDetails.mobile)
										self.el.userData.find('#phone').val(userDetails.phone)
										self.el.userData.find('#email').val(userDetails.email)
									})

									self.el.resultsContainer.find('ul').append(item).closest('.results').show();
								})

							}

						}).fail(function (jqXHR, textStatus) {

							if (jqXHR.status === 400) {
								self.el.resultsContainer.find('li').remove();
								self.el.resultsContainer.find('ul').append('<li><p class="full-width">' + jqXHR.responseText).closest('.results').show();

							}
							if (jqXHR.status === 404) {

								self.el.resultsContainer.find('li').remove();
								self.el.resultsContainer.find('ul').append('<li><p class="full-width">' + selfObj.data('msg-no-results')).closest('.results').show();

							}
						})
					}


				}


			})
		})
	},

	selectEvents: function () {
		var self = this;
		this.el.select.on('change', function () {
			self.el.registrationContainer.find('.results').show();
		});
	},

	init: function () {
		this.postEvents();
		//this.selectEvents();
		this.signupEvents();
	}
}

registration.init();