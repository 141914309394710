var charts = {
	el: {
		barContainer: $('.js-chart-bar'),
		vennContainer: $('.js-chart-venn'),
		navigationContainer: $('.js-chart-navigation')
	},

	barChart: function() {
		this.el.barContainer.each(function(){
			var chartValues = JSON.parse("[" + $(this).data('values') + "]")
			var isLayoutV2 = $(this).closest('body').hasClass('layout-v2')
			Highcharts.chart($(this).attr('id'), {
				chart: {
					inverted: true,
					style: {
						fontFamily: isLayoutV2 ? 'Uniform Reg' : 'Gotham',
						fontWeight: 500
					} 
				},
				xAxis: {
					categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					labels: {
						style: {
							color: isLayoutV2 ? "#470047" : "#9186A5",
							fontSize: "12px"
						}
					},
				},
				yAxis: {
					labels: {
						format: '{value} pts',
						style: {
							color: isLayoutV2 ? "#470047" : "#9186A5",
							fontSize: "12px"
						}
					},
					title: {
						enabled: false
					}
				},
				plotOptions: {
					series: {
						color: isLayoutV2 ? '#A5B0C1' : '#D8D5E0'
					},
					column: {
						pointWidth: 20,
					}
				},
				credits: {
					enabled: false
				},
				title: {
					text: undefined
				},
				series: [{
					type: 'column',
					data: chartValues,
					showInLegend: false,
					states: {
						hover: {
							color: isLayoutV2 ? "#470047":"#A8113E"
						}
					}
				}],
				tooltip: {
					headerFormat: "<span class='tooltip-row-first'>{point.key}</span><br/>",
					pointFormat: "<span class='tooltip-row-second'>{point.y}pts</span>",
					backgroundColor: '#ffffff',
					borderWidth: 0,
					useHTML: true,
					shadow: {
						color: "rgb(173,173,173)",
						offsetX: 2,
						offsetY: 2,
						width: 10,
						opacity: 0.1
					},
					style: {
						textAlign: 'center'
					}
				}
			})
		})
	},

	vennChart: function () {
		this.el.vennContainer.each(function(){
			$(this).closest('.list__row-field').find('.available-points').html($(this).data('points-available'))
			$(this).closest('.list__row-field').find('.expiring-points').html($(this).data('points-expiring'))
			$(this).find('.set__outer-value').html($(this).data('points-available'))
			$(this).find('.set__inner-value').html($(this).data('points-expiring'))
			var setInnerSize = ($(this).data('points-expiring') * 100) / $(this).data('points-available')
			$(this).find('.set__inner').css('height', setInnerSize + '%')
			$(this).find('.set__inner').css('width', setInnerSize + '%')
			if (setInnerSize > 80) {
				$(this).find('.set__outer-value').css('top', '-20px')
				$(this).find('.set__outer-value').addClass('out')
			} else {
				$(this).find('.set__outer-value').css('top', 'calc('+((100 - setInnerSize)/2) + '% - 10px)')
				$(this).find('.set__outer-value').removeClass('out')
			}
		})
	},

	navigation: function() {
		this.el.navigationContainer.each(function(){
			
			var activeChart = $(this).find('.chart-active'),
				yearContainer = $(this).find('.list__navigation-text').html(activeChart.data('year')),
				btnPrev = $(this).find('.list__navigation-prev'),
				btnNext = $(this).find('.list__navigation-next'),
				self = this
			activeChart.show()
			btnNext.on('click', function(){
				if($(self).find('.chart-active').data('highcharts-chart') < ($(self).find('.chart').length) - 1) {
					var currentlyActive = $(self).find('.chart-active')
					currentlyActive.removeClass('chart-active').next().addClass('chart-active')
					$(self).find('.chart').fadeOut(200)
					setTimeout(function(){
						$(self).find('.chart:not(chart-active)').hide()
						currentlyActive.next().fadeIn(200)
					}, 200)

					yearContainer.html($(self).find('.chart-active').data('year'))
					$(this).removeClass('disabled')
					btnPrev.removeClass('disabled')
				}

				if($(self).find('.chart-active').data('highcharts-chart') == ($(self).find('.chart').length) - 1) {
					$(this).addClass('disabled')
				}
			})

			btnPrev.on('click', function(){
				if($(self).find('.chart-active').data('highcharts-chart') > 0) {
					var currentlyActive = $(self).find('.chart-active')
					currentlyActive.removeClass('chart-active').prev().addClass('chart-active')
					$(self).find('.chart').fadeOut(200)
					setTimeout(function(){
						$(self).find('.chart').hide()
						currentlyActive.prev().fadeIn(200)
					}, 200)
					yearContainer.html($(self).find('.chart-active').data('year'))
					$(this).removeClass('disabled')
					btnNext.removeClass('disabled')
				} 
				
				if($(self).find('.chart-active').data('highcharts-chart') == 0) {
					$(this).addClass('disabled')
				}
			})
		})
	},

	init: function() {
		this.barChart()
		this.navigation()
		this.vennChart()
	}
}

charts.init()