var catalog = {
	el: {
		doc: $(document),
		aside: $('aside'),
		filtersBar: $('.filters-bar'),
		table: $('.table'),
		productDetail: $('.product-detail'),
		productOrder: $('.product-order'),
		productOrderHeader: $('.product-order-header'),
		addFavorite: $('.add-favorite'),
		btnNext: $('a.next'),
		btnPrev: $('a.prev'),
		stepOne: $('.step-one'),
		stepTwo: $('.step-two'),
		stepThree: $('.step-three'),
		productsBlock: $('.products-block'),
		highlights: $('.highlights'),
		checkboxAddress: $('#correct-data'),
		body: $('body')

	},
	events: function () {

		var self = this;

		if(this.el.body.hasClass('layout-v2')) {
			this.el.filtersBar.find('a').on('click', function () {
				$(this).parent().siblings().removeClass('selected');
				$(this).parent().addClass('selected');
				getList.events(this)
			});
	
			this.el.doc.on('change', '.filters-bar-responsive', function () {
				getList.events($(this).find(':selected'))
			})
	
			this.el.doc.on('click', '.prizes .item', function () {
				getDetail.events(this)
	
			});
	
			this.el.doc.on('click', '.product-list .item', function () {
				getDetail.events(this);
				self.el.table.hide();
				self.el.productDetail.show()
			});
	
			this.el.doc.on('click', '.change-points', function () {
				self.el.productDetail.hide();
				self.el.productOrder.show();
				self.el.stepOne.show();
				self.el.stepTwo.add(self.el.stepThree).hide();
				self.el.productOrderHeader.find('li').siblings().removeClass('selected');
				self.el.productOrderHeader.find('li').first().addClass('selected')
			});
	
			this.el.productsBlock.find('.products-v2__item').on('click', function () {
				getDetail.events(this)
				self.el.table.add(self.el.highlights).hide();
				self.el.productDetail.show()
			})
		}

		
	},

	navigation: function () {
		var self = this;



		this.el.stepTwo.add(this.el.stepThree).hide();

		this.el.btnNext.on('click', function () {
			self.el.btnPrev.show();
			self.el.productOrderHeader.find('li').siblings().removeClass('selected');

			if (self.el.stepThree.is(':visible')) {
				self.finishOrder(this)
			}

			if (self.el.stepTwo.is(':visible')) {
				if (!self.el.checkboxAddress.prop('checked')) {
					alert(self.el.checkboxAddress.data('msg'))
					self.el.productOrderHeader.find('li').first().next().addClass('selected')
				} else {
					self.el.stepTwo.hide()
					self.el.stepThree.show()
					self.el.productOrderHeader.find('li').last().addClass('selected')
					self.el.btnNext.text('FINALIZAR')
				}
			}

			if (self.el.stepOne.is(':visible')) {
				self.el.stepTwo.show()
				self.el.stepOne.hide()
				self.el.productOrderHeader.find('li').first().next().addClass('selected')
			}
		})
		this.el.btnPrev.on('click', function () {
			self.el.productOrderHeader.find('li').siblings().removeClass('selected');
			self.el.checkboxAddress.prop('checked', false)

			if (self.el.stepTwo.is(':visible')) {
				$(this).hide()
				self.el.stepOne.show()
				self.el.stepTwo.hide()
				self.el.productOrderHeader.find('li').first().addClass('selected')

			}
			if (self.el.stepThree.is(':visible')) {
				self.el.stepTwo.show()
				self.el.stepThree.hide()
				self.el.productOrderHeader.find('li').first().next().addClass('selected')
				self.el.btnNext.text('AVANÇAR')
			}

		})
	},

	finishOrder: function (e) {
		var self = this;

		$.ajax({
			url: self.el.aside.data('order-endpoint') + $(e).data('id'),
			type: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: $(e).data('id'),
			beforeSend: function () {
				$(e).text('A CARREGAR')
			},

		}).done(function (data) {
			self.el.productOrder.find('.message-success').show();
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information')).hide();


		}).fail(function (jqXHR) {
			console.error(jqXHR.responseJSON.message)
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information').add(self.el.productOrderHeader)).hide();
			self.el.productOrder.append(`
				<div class="order-error">${jqXHR.status === 400 ? jqXHR.responseJSON.message: "Ocorreu um erro tente novamente"}</div>
				<a href='/Catalog/Products' class='btn btn--red'>Voltar</a>
			`)

		})
	},

	init: function () {
		this.events();
		this.navigation();
	}
}
catalog.init();