var url = {
	el: {
		externalLinks: $("a[target='_blank']")
	},

	events: function (url) {
		var newTab = window.open();
		newTab.opener = null;
		newTab.location = url;
	},

	init: function () {
		var self = this;
		this.el.externalLinks.on('click', function () {
			self.events(this);
		})
	}
}

url.init();