getDetail = {
	el: {
		aside: $('aside'),
		productDetail: $(".product-detail"),
		productOrder: $('.product-order'),
		filtersBar: $('.filters-bar'),
		filtersBarResponsive: $('.filters-bar-responsive')
	},

	events: function(e) {

		var self = this;

		self.el.filtersBar.add(self.el.filtersBarResponsive).hide();

		$.ajax({
			type: "GET",
			url: self.el.aside.data('products-details-endpoint') + $(e).data('id'),
			dataType: 'json',
			contentType: 'application/json',
			beforeSend: function() {
				self.el.productDetail.append('<div class="loading">A carregar</div>')
			},

		}).done(function(data) {
			$('.loading').remove();
			self.el.productDetail.html('')
			self.el.productDetail.append(`
			<div class="product-detail-header">
				<h2>${data.name}</h2>
			</div>
			<div class="product-detail-container">
				<div class="product-detail-image">
					<img src="${data.image}" alt="${data.name}">
				</div>
				<div class="product-detail-description">				
						<p>${data.contents}</p>				
				</div>
				<div class="product-detail-data">
					<div class="data-wrapper">
						<p class="points"><strong>${data.points}</strong> pts</p>
						<p class="available-points">Pontos disponíveis: <span>${data.availablePoints}</span></p>
					</div>
				</div>
			</div>
			`);
			if (data.availablePoints >= data.points && data.stock > 0)
				$('.data-wrapper').append(`
					<a class="change-points btn btn--red" href="javascript:;"> Trocar prémio
					<img src="/assets/img/icons/ico_cart.svg" alt="Trocar prémio" />
				</a>
				`)
			self.el.productOrder.find('img').attr({
				'src': data.image,
				'alt': data.name
			})
			self.el.productOrder.find('.js-name').text(data.name)
			self.el.productOrder.find('.points').text(data.points + ' pts')
			self.el.productOrder.find('a.next').attr('data-id', data.id)
		})
	},
}
